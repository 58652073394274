import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

/*
 * Extracts the coordinates from the features and then updates
 * the map to include all coordinates in the viewport.
 */
export const fitMapBoundsToPoints = (map, features) => {
  const points = features.map(feature => feature.geometry.coordinates);

  // Avoid trying to fit map and throwing spurious errors if no points
  if (points.length === 0) return;

  // eslint-disable-next-line max-len
  const bounds = points.reduce((accum, coord) => accum.extend(coord), new mapboxgl.LngLatBounds(points[0], points[0]));

  map.fitBounds(bounds, {
    padding: {
      bottom: 25,
      left: 15,
      right: 5,
      top: 10,
    },
  });
};
