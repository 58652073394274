import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  Button,
  FormControlLabel, Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {
  Autorenew as AutorenewIcon,
} from '@mui/icons-material';

import {
  CardField,
  TabPanel,
  Tabs,
  TargetsSelectionTable,
} from 'common/components';
import {
  contentBlockType,
  targetsType,
} from 'common/typings';

import {
  colors,
  messagePairTypes,
  subjectMessageEmptyPair,
} from 'utils/constants';
import SubjectMessageCreator from '../SubjectMessageCreator';

const ExplanationWrapper = styled.div`
  margin: 40px auto 10px;
  padding: 15px;
  background-color: ${colors.lightGold};
`;

const StyledGrid = styled(Grid)`
  flex: 0 0 100%;
`;

const CardFieldWrapper = styled.div`
  margin-top: 30px;
`;

const StyledTextField = styled(TextField)`
  margin: 10px;
`;

const TalkingPointsList = styled.ul`
  position: relative;
  width: 100%;
  margin: 10px;
`;

const TakingPointItem = styled.li`
  position: relative;
  padding-bottom: 12px;
  list-style: none;
  font-size: 16px;

  &::after {
    content: "";
    position: absolute;
    top: 9px;
    left: -18px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${colors.dodgerBlue};
  }
`;

const NewPrefilledButton = styled(Button)`
  display: block;
  margin-top: 15px;
`;

const StyledTypography = styled(Typography)`
  margin-top: 20px;
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  display: inline-flex;
`;

const SwitchButton = styled(Button)`
  margin: 10px 0 0 10px;
`;

const StepThree = ({
  actionMode,
  canDisableMessageApproval,
  communicationChannel,
  isCurrentStep,
  messageTabIndex,
  setMessageTabIndex,
  setSubjectMessagesRotationPairData,
  setSubjectMessagesMappingPairData,
  setTalkingPoints,
  setTargets,
  setUsesMessageApproval,
  showMessageApproval,
  showMessageMapping,
  subjectMessagesRotationPairData,
  subjectMessagesMappingPairData,
  talkingPoints,
  targets,
  usesMessageApproval,
}) => {
  const [
    mappedMessageTabIndex,
    setMappedMessageTabIndex,
  ] = useState(0);

  const handleAddSubjectMessagePair = () => {
    setSubjectMessagesRotationPairData(prevPrefilledSubjects => [
      ...prevPrefilledSubjects,
      {
        body: [
          {
            ...subjectMessageEmptyPair.body[0],
            uuid: uuid(),
          },
        ],
        isAllowPersonalMessage: subjectMessageEmptyPair.isAllowPersonalMessage,
        subject: {
          ...subjectMessageEmptyPair.subject,
        },
        uuid: uuid(),
      },
    ]);
  };

  const handleMoveSelectedTargetToTargetList = (checked, targetIndex) => {
    setTargets(prevTargets => {
      const newTargets = [...prevTargets];

      newTargets[targetIndex].mappedMessage =
        subjectMessagesMappingPairData[
          newTargets[targetIndex].mappedMessage === subjectMessagesMappingPairData[1].uuid ? 0 : 1
        ].uuid;

      return newTargets;
    });
  };

  const handleSelectAllTargets = mappedTargetsUuid => {
    setTargets(prevTargets => {
      const newTargets = prevTargets.map(target => ({
        ...target,
        mappedMessage: mappedTargetsUuid,
      }));

      return newTargets;
    });
  };

  const {
    messageRotation,
    messageMapping,
  } = messagePairTypes;
  const messageTabs = [
    {
      label: actionMode === 'petition' ? 'Petition Letter' : 'Message Rotation',
      value: messageRotation,
    },
    {
      label: 'Message Mapping',
      value: messageMapping,
    },
  ];

  const mappedMessageTabs = [
    {
      label: 'Message 1',
      value: 0,
    },
    {
      label: 'Message 2',
      value: 1,
    },
  ];

  const isMessageRotation = messageTabIndex === messageRotation;

  const canAddMore = () => actionMode !== 'petition';

  const slicedRotationData = actionMode === 'petition' ? subjectMessagesRotationPairData.slice(0, 1) : subjectMessagesRotationPairData;

  const shouldHideDuplicateMessageButton = () => actionMode === 'petition';

  const shouldHideDeleteMessageButton = () => subjectMessagesRotationPairData.length === 1;

  return (
    <>
      {showMessageMapping && (
        <>
          <StyledTabs
            handleSetTab={setMessageTabIndex}
            tabs={[messageTabs[isMessageRotation ? 0 : 1]]}
            tabIndex={messageTabIndex}
          />
          <SwitchButton
            color="primary"
            onClick={() => setMessageTabIndex(isMessageRotation ? messageMapping : messageRotation)}
            startIcon={<AutorenewIcon />}
          >
            {`Switch to ${isMessageRotation ? 'Message Mapping' : 'Message Rotation'}`}
          </SwitchButton>
        </>
      )}
      {communicationChannel.email && (
        <CardFieldWrapper>
          <CardField
            name={messageTabs.find(tab => tab.value === messageTabIndex).label}
            flexwrap
          >
            <StyledTabPanel
              index={messageTabs[0].value}
              value={messageTabIndex}
            >
              {slicedRotationData.map((subjectMessagesPair, index) => (
                <SubjectMessageCreator
                  allowEditing={actionMode !== 'petition'}
                  hideDeleteButton={shouldHideDeleteMessageButton()}
                  hideDuplicateMessageButton={shouldHideDuplicateMessageButton()}
                  key={subjectMessagesPair.uuid}
                  messageIndex={index}
                  setSubjectMessagesPairData={setSubjectMessagesRotationPairData}
                  showMessageIndex={slicedRotationData.length > 1}
                  subjectMessagesPairData={slicedRotationData[index]}
                />
              ))}
              {canAddMore() && (
              <NewPrefilledButton
                color="secondary"
                onClick={handleAddSubjectMessagePair}
              >
                Add new message
              </NewPrefilledButton>
              )}
            </StyledTabPanel>
            <StyledTabPanel
              index={messageTabs[1].value}
              value={messageTabIndex}
            >
              {showMessageMapping && isCurrentStep && (
                <>
                  <Tabs
                    handleSetTab={setMappedMessageTabIndex}
                    tabs={mappedMessageTabs}
                    tabIndex={mappedMessageTabIndex}
                  />
                  <StyledTabPanel
                    index={mappedMessageTabs[0].value}
                    value={mappedMessageTabIndex}
                  >
                    <SubjectMessageCreator
                      hideDeleteButton
                      hideDuplicateMessageButton
                      messageIndex={0}
                      setSubjectMessagesPairData={setSubjectMessagesMappingPairData}
                      showMessageIndex={false}
                      subjectMessagesPairData={subjectMessagesMappingPairData[0]}
                    />
                  </StyledTabPanel>
                  <StyledTabPanel
                    index={mappedMessageTabs[1].value}
                    value={mappedMessageTabIndex}
                  >
                    <SubjectMessageCreator
                      hideDeleteButton
                      hideDuplicateMessageButton
                      messageIndex={1}
                      setSubjectMessagesPairData={setSubjectMessagesMappingPairData}
                      showMessageIndex={false}
                      subjectMessagesPairData={subjectMessagesMappingPairData[1]}
                    />
                  </StyledTabPanel>
                  <TargetsSelectionTable
                    hasSaveTargetsButton={false}
                    hasDeleteButton={false}
                    handleClickActionIcon={handleMoveSelectedTargetToTargetList}
                    handleSelectAllTargets={handleSelectAllTargets}
                    mappedTargetsUuid={subjectMessagesMappingPairData[mappedMessageTabIndex].uuid}
                    mappedMessageTabIndex={mappedMessageTabIndex}
                    moveToUuid={subjectMessagesMappingPairData[0].uuid}
                    targets={targets}
                  />
                </>
              )}
            </StyledTabPanel>
          </CardField>
        </CardFieldWrapper>
      )}
      {showMessageApproval && (
        <CardFieldWrapper>
          <CardField name="Message Approval">
            <StyledGrid>
              <RadioGroup>
                <FormControlLabel
                  checked={!usesMessageApproval}
                  control={<Radio />}
                  disabled={!canDisableMessageApproval}
                  onChange={() => {
                    setUsesMessageApproval(false);
                  }}
                  label="Send advocate's messages directly"
                />
                <FormControlLabel
                  checked={usesMessageApproval}
                  control={<Radio />}
                  disabled={!canDisableMessageApproval}
                  onChange={() => {
                    setUsesMessageApproval(true);
                  }}
                  label="Enable message approval"
                />
              </RadioGroup>
              {usesMessageApproval && (
                <ExplanationWrapper>
                  <Typography
                    variant="caption"
                    component="p"
                    display="block"
                    gutterBottom
                  >
                    <strong>
                      ⚠️ You cannot disable message approval while there are pending messages.
                      Approve or reject these messages first.
                    </strong>
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    display="block"
                    gutterBottom
                  >
                    Message approval is content-based. New advocate messages exactly matching
                    prefilled messages or previously approved messages will be auto-approved
                    and submitted. New advocate messages exactly matching previously rejected
                    messages will but auto-rejected.
                  </Typography>
                </ExplanationWrapper>
              )}
            </StyledGrid>
          </CardField>
        </CardFieldWrapper>
      )}
      {communicationChannel.phone && (
        <CardFieldWrapper>
          <CardField
            name="Talking Points"
            flexwrap
          >
            <StyledTextField
              fullWidth
              label="Talking Points"
              multiline
              onChange={event => setTalkingPoints(event.target.value)}
              type="text"
              value={talkingPoints}
            />
            <StyledTypography>Preview:</StyledTypography>
            <TalkingPointsList>
              {talkingPoints.split('\n').map((talkingPoint, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TakingPointItem key={`${talkingPoint}_${index}`}>{talkingPoint}</TakingPointItem>
              ))}
            </TalkingPointsList>
          </CardField>
        </CardFieldWrapper>
      )}
    </>
  );
};

StepThree.propTypes = {
  actionMode: PropTypes.string.isRequired,
  canDisableMessageApproval: PropTypes.bool.isRequired,
  communicationChannel: PropTypes.shape({
    email: PropTypes.bool,
    phone: PropTypes.bool,
    twitter: PropTypes.bool,
    usmail: PropTypes.bool,
  }).isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  messageTabIndex: PropTypes.oneOf([
    'mapping',
    'rotation',
  ]).isRequired,
  setMessageTabIndex: PropTypes.func.isRequired,
  setSubjectMessagesMappingPairData: PropTypes.func.isRequired,
  setSubjectMessagesRotationPairData: PropTypes.func.isRequired,
  setTalkingPoints: PropTypes.func.isRequired,
  setTargets: PropTypes.func.isRequired,
  setUsesMessageApproval: PropTypes.func.isRequired,
  showMessageApproval: PropTypes.bool.isRequired,
  showMessageMapping: PropTypes.bool.isRequired,
  subjectMessagesMappingPairData: PropTypes.arrayOf(PropTypes.shape({
    isAllowPersonalMessage: PropTypes.bool,
    messages: PropTypes.arrayOf(contentBlockType),
    subject: contentBlockType,
    uuid: PropTypes.string,
  })).isRequired,
  subjectMessagesRotationPairData: PropTypes.arrayOf(PropTypes.shape({
    isAllowPersonalMessage: PropTypes.bool,
    messages: PropTypes.arrayOf(contentBlockType),
    subject: contentBlockType,
    uuid: PropTypes.string,
  })).isRequired,
  talkingPoints: PropTypes.string.isRequired,
  targets: targetsType.isRequired,
  usesMessageApproval: PropTypes.bool.isRequired,
};

export default StepThree;
