import {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Diff from 'text-diff';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  DangerousOutlined as DangerousOutlinedIcon,
  NotInterested as NotInterestedIcon,
  ThumbDownOutlined as ThumbDownOutlinedIcon,
  ThumbUpAltOutlined as ThumbUpAltOutlinedIcon,
} from '@mui/icons-material';

import {
  fetchActionCenterActionDetail,
  patchActionCenterActionDetail,
} from 'store/actions/campaigns';
import { campaignsSelectors } from 'store/selectors/campaigns';

import { CircularProgressWrapper } from 'globalStyles';
import {
  actionTakenMessageStatus,
  colors,
} from 'utils/constants';

const StyledDialogContentText = styled(DialogContentText)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const SubjectWrapper = styled.div`
  margin-top: 30px;
`;

const MessageWrapper = styled.div`
  margin: 10px 0 30px;
  white-space: pre-wrap;

  ins {
    color: ${colors.secondary};
  }

  del {
    color: ${colors.warning};
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
`;

const ActionTakenModal = ({
  action,
  setAction,
  showMessageApproval,
  updateActions,
}) => {
  const dispatch = useDispatch();
  const { actionCenterUuid } = useParams();
  const {
    actionCenterDetails,
  } = useSelector(state => ({
    actionCenterDetails: campaignsSelectors.getActionCenterDetails(state),
  }));
  const [
    isDialogOpen,
    setIsDialogOpen,
  ] = useState(false);
  const [
    isLoading,
    setIsLoading,
  ] = useState(false);
  const [
    actionDetails,
    setActionDetails,
  ] = useState({});
  const [
    messageDiff,
    setMessageDiff,
  ] = useState({
    message: '',
    subject: '',
  });

  const {
    approvedMessage,
    flaggedMessage,
    pendingMessage,
    quarantinedMessage,
    rejectedMessage,
   } = actionTakenMessageStatus;

  const handleSetMessageDiff = ({
    message,
    subject,
    messageId,
  }) => {
    setMessageDiff(() => {
      let diffMessage = {
        message: '',
        subject: '',
      };
      const diff = new Diff();

      const baseMessageSubjectPair = actionCenterDetails.messages
        .find(({ uuid }) => uuid === messageId);

      const messageBase = baseMessageSubjectPair?.body.map(({ name }) => name).join('\n\n') || '';
      const subjectBase = baseMessageSubjectPair?.subject.name || '';

      const messageTextDiff = diff.main(messageBase, message); // produces diff array
      const subjectTextDiff = diff.main(subjectBase, subject);

      // Increase human readability by factoring out commonalities
      // which are likely to be coincidental
      diff.cleanupSemantic(messageTextDiff);
      diff.cleanupSemantic(subjectTextDiff);

      diffMessage = {
        message: diff.prettyHtml(messageTextDiff), // produces a formatted HTML string
        subject: diff.prettyHtml(subjectTextDiff),
      };

      return diffMessage;
    });
  };

  useEffect(() => {
    if (action) {
      setIsLoading(true);
      setIsDialogOpen(true);
      dispatch(fetchActionCenterActionDetail(actionCenterUuid, action))
        .then(data => {
          setActionDetails(data);

          if (action?.type === 'email') {
            handleSetMessageDiff(data);
          }
          setIsLoading(false);
        });
    }
  }, [
    action,
    actionCenterUuid,
    dispatch,
  ]);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setAction(null);
    setActionDetails({});
  };

  const handleChangeActionDetailStatus = status => {
    dispatch(patchActionCenterActionDetail(actionCenterUuid, action, { messageStatus: status }))
      .then(data => {
        updateActions(data.messageStatus, data.targetEmailUuids);
        handleDialogClose();
      });
  };

  const {
    callDuration,
    callStatus,
    email,
    firstName,
    lastName,
    createdAt,
    messageStatus,
    targetFirstName,
    targetOfficeTitle,
    targetIsActive,
  } = actionDetails;

  return (
    <Dialog
      fullWidth
      onClose={handleDialogClose}
      open={isDialogOpen}
      scroll="paper"
    >
      <StyledDialogTitle disableTypography>
        <Typography variant="h6">Action Details</Typography>
        {showMessageApproval && messageStatus === approvedMessage && (
          <CheckCircleOutlineIcon
            color="success"
            titleAccess="Approved"
          />
        )}
        {showMessageApproval && messageStatus === rejectedMessage && (
          <NotInterestedIcon
            color="error"
            titleAccess="Rejected"
          />
        )}
        {showMessageApproval && messageStatus === quarantinedMessage && (
          <DangerousOutlinedIcon
            color="error"
            titleAccess="Quarantined"
          />
        )}
        {showMessageApproval &&
          [
            flaggedMessage,
            pendingMessage,
          ].some(status => messageStatus === status) && (
            <div>
              <IconButton
                onClick={() => handleChangeActionDetailStatus('rejected')}
                size="large"
              >
                <ThumbDownOutlinedIcon color="error" />
              </IconButton>
              {targetIsActive ? (
                <IconButton
                  onClick={() => handleChangeActionDetailStatus('approved')}
                  size="large"
                >
                  <ThumbUpAltOutlinedIcon color="secondary" />
                </IconButton>
                ) :
                (
                  <IconButton
                    disabled
                    size="large"
                  >
                    <ThumbUpAltOutlinedIcon color="placeholder" />
                  </IconButton>
                )
              }
            </div>
          )}
      </StyledDialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgressWrapper>
            <CircularProgress
              color="inherit"
              size={20}
            />
          </CircularProgressWrapper>
        ) : (
          <>
            <StyledDialogContentText>
              <span>
                {firstName}
                {' '}
                {lastName}
                {' '}
                Email:
                {' '}
                {email}
              </span>
              <span>
                Date:
                {' '}
                {new Date(createdAt).toLocaleDateString('us-US')}
              </span>
            </StyledDialogContentText>
            <StyledDialogContentText>
              <span>
                Sent to:
                {' '}
                {targetFirstName}
                {', '}
                {targetOfficeTitle}
              </span>
            </StyledDialogContentText>
            <StyledDialogContentText>
              {!targetIsActive && showMessageApproval && messageStatus === pendingMessage && (
                <strong>
                  Pending messages to inactive targets can be rejected,
                  but cannot be approved and sent while the target is inactive.
                </strong>
              )}
            </StyledDialogContentText>
            <SubjectWrapper>
              <MessageWrapper
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messageDiff.subject,
                }}
              />
            </SubjectWrapper>
            {callDuration && (
              <div>{`Call duration: ${callDuration}`}</div>
            )}
            {callStatus && (
              <div>{`Call status: ${callStatus}`}</div>
            )}
            {messageDiff && messageDiff.message && (
              <MessageWrapper
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: messageDiff.message,
                }}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

ActionTakenModal.defaultProps = {
  action: null,
};

ActionTakenModal.propTypes = {
  action: PropTypes.shape({
    messageStatus: PropTypes.oneOf([
      'approved',
      'flagged',
      'pending',
      'quarantined',
      'rejected',
    ]),
    type: PropTypes.string,
    uuid: PropTypes.string,
  }),
  setAction: PropTypes.func.isRequired,
  showMessageApproval: PropTypes.bool.isRequired,
  updateActions: PropTypes.func.isRequired,
};

export default ActionTakenModal;
